import StyledCarousel from './StyledCarousel';
import React, { useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import fetchImgs from '../api/gallery-api';

const CarouselWithFullscreenImages = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgs, setImgs] = useState([]);

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  useEffect(() => {
    fetchImgs().then((res) => {
      setImgs(res?.data);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      <StyledCarousel title={t('gallery')}>
        {imgs && imgs?.length
          ? imgs.map((image, index) => (
              <div key={index} style={{ padding: '10px' }}>
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                    image.attributes.img?.data?.attributes.formats.small.url
                  }
                  alt={`img-${index}`}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '15px',
                  }}
                  onClick={() =>
                    handleClickOpen(
                      process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                        image.attributes.img?.data?.attributes.formats.large.url
                    )
                  }
                />
              </div>
            ))
          : null}
      </StyledCarousel>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <img src={selectedImage} alt="Fullscreen" style={{ width: '100%' }} />
      </Dialog>
    </div>
  );
};

export default CarouselWithFullscreenImages;
