import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import querystring from 'querystring';
import { saveExcel, parseQuotationExcel } from './excel';
import * as quotationApi from '../api/quotation-api';
import * as deliveryApi from '../api/delivery-api';
import * as dictionaryApi from '../api/dictionary-api';
import { useSearchContext } from '../store/searchContext';

const useSearch = (location, history) => {
  const { t } = useTranslation();
  const {
    search,
    setSearch,
    deliveryTerm,
    setDeliveryTerm,
    deliveryTariff,
    setDeliveryTariff,
    currency,
    setCurrency,
    tab,
    setTab,
  } = useSearchContext();

  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [data, setData] = useState([]);
  const [isFileUploading, setFileUploading] = useState(false);

  const [deliveryTariffList, setDeliveryTariffList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const lastQuotationId = useRef(0);
  const initialized = useRef(false);

  const openFileDialog = useRef();

  const columns = useMemo(
    () => [
      { name: 'PartNumber', width: 10 },
      { name: 'Brand', width: 10 },
      { name: 'Quantity' },
      { name: 'Price' },
      { name: 'Reference' },
      { name: 'Warehouse' },
      { name: 'Days' },
      { name: 'Description' },
      { name: 'Substituted' },
      { name: 'WeightKG' },
      { name: 'VolumeKG' },
    ],
    []
  );

  const outputData = useMemo(
    () =>
      data.map((value) => [
        value.partNumber,
        value.brand,
        value.quantity &&
        (!value.available || value.quantity <= value.available)
          ? value.quantity
          : value.available,
        value.price,
        value.yourReference,
        value.booking,
        value.days,
        value.description,
        value.inpPartNumber !== value.partNumber ? value.inpPartNumber : '',
        value.weightKg,
        value.volumeKg,
      ]),
    [data]
  );

  async function getDeliveryTariffList() {
    const result = await deliveryApi.deliveries(true, false);
    setDeliveryTariffList(
      result.map((row) => ({
        title: row['text'],
        value: row['id'],
      }))
    );

    if (result.length !== 0 && !deliveryTariff) setDeliveryTariff(result[0].id);
  }

  async function getCurrencyList() {
    const result = await dictionaryApi.currencies();
    setCurrencyList(
      result.map((row) => ({
        title: row['code'],
        value: row['code'],
      }))
    );

    if (result.length !== 0 && !currency) setCurrency(result[0].code);
  }

  useEffect(() => {
    lastQuotationId.current = 0;
    fetchDataBySearch();
  }, [location.search]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDeliveryTariffList().then();
      getCurrencyList().then();
    }
  }, []);

  useEffect(() => {
    fetchOnly().then();
  }, [currency]);

  useEffect(() => {
    lastQuotationId.current = 0;
    setData([]);
  }, [deliveryTariff, deliveryTerm, tab]);

  async function fetchData(query, maxDays, onlyBest) {
    setData([]);
    const result = await quotationApi.process(
      query,
      maxDays,
      onlyBest,
      currency
    );
    setData(result);

    if (result && result.length) {
      lastQuotationId.current = result[0].quotationId;
    }
  }

  async function fetchOnly() {
    if (lastQuotationId.current) {
      setData([]);
      const result = await quotationApi.select(
        lastQuotationId.current,
        currency
      );
      setData(result);
    }
  }

  function handleChangeCurrency(value) {
    setCurrency(value);
  }

  function fetchDataBySearch() {
    const params = querystring.parse(location.search.slice(1));
    if (params.part !== undefined) {
      setSearch(params.part);
      fetchData(
        [{ partNumber: params.part, delivery: deliveryTariff }],
        0,
        false
      ).then();
    }
  }

  function handleSearchInputChange(value) {
    setSearch(value);
  }

  function handleSearchInputSearch() {
    const params = querystring.parse(location.search.slice(1));
    if (params.part === search) fetchDataBySearch();
    else
      history.push({
        pathname: '/search',
        search: `?part=${search}`,
      });
  }

  function handleDataChange(data) {
    setData(data);
  }

  function handleDeliveryTermChange(value) {
    setDeliveryTerm(value);
  }

  async function handleTableSelect(id) {
    const newData = data.map((item) => {
      return item.id !== id ? item : { ...item, confirmed: !item.confirmed };
    });
    setData(newData);
  }

  async function handleTableSelectAll(confirmed) {
    const newData = data.map((item) => {
      return { ...item, confirmed };
    });
    setData(newData);
  }

  async function handleToBasket() {
    if (isLoggedIn)
      try {
        const { quotationId } = data[0];
        if (quotationId && quotationId > 0) {
          await quotationApi.confirmAndToBasket(
            quotationId,
            data.filter((value) => value.quantity > 0)
          );
          setData([]);
          lastQuotationId.current = 0;
          history.push('/basket');
        }
      } catch (e) {
        // empty
      }
  }

  async function handleToExcel() {
    await saveExcel('Quotation', columns, outputData);
  }

  function handleTabChange(event, value) {
    setTab(value);
  }

  function handleFileOpen() {
    openFileDialog.current.click();
  }

  async function handleDeliveryTariffChange(value) {
    setDeliveryTariff(value);

    if (!lastQuotationId.current) return;

    const result = await quotationApi.refresh(
      lastQuotationId.current,
      value,
      0,
      false,
      currency
    );
    lastQuotationId.current = result[0].quotationId;
    setData(result);
  }

  async function handleQuotationExcel(event, file = null) {
    setFileUploading(true);
    let excelData = [];
    if (file) {
      excelData = await parseQuotationExcel(file);
    } else {
      try {
        const file = event.target.files[0];
        event.target.value = '';
        if (!file) return;
        excelData = await parseQuotationExcel(file);
      } catch (err) {
        alert(err.message);
      }
    }
    if (!excelData.length) {
      setFileUploading(false);
      return;
    }
    try {
      await fetchData(
        excelData.map((row) => ({
          partNumber: row['PartNumber'],
          brand: row['Brand'],
          quantity: row['Quantity'],
          price: row['Price'],
          yourReference: row['Reference'],
          booking: row['Warehouse'],
          delivery: deliveryTariff,
        })),
        deliveryTerm,
        true
      );

      setFileUploading(false);
    } catch (err) {
      alert(t('error_quotation'));
      setFileUploading(false);
    }
  }

  return {
    isLoggedIn,
    search,
    setSearch,
    data,
    setData,
    deliveryTerm,
    setDeliveryTerm,
    tab,
    setTab,
    isFileUploading,
    setFileUploading,
    deliveryTariffList,
    currencyList,
    lastQuotationId,
    initialized,
    deliveryTariff,
    setDeliveryTariff,
    currency,
    setCurrency,
    openFileDialog,
    columns,
    outputData,
    getDeliveryTariffList,
    getCurrencyList,
    fetchData,
    fetchOnly,
    handleChangeCurrency,
    fetchDataBySearch,
    handleSearchInputChange,
    handleSearchInputSearch,
    handleDataChange,
    handleTableSelect,
    handleTableSelectAll,
    handleToBasket,
    handleToExcel,
    handleTabChange,
    handleFileOpen,
    handleDeliveryTermChange,
    handleDeliveryTariffChange,
    handleQuotationExcel,
  };
};

export default useSearch;
