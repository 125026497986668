import React, { useEffect, useState } from 'react';
import { Grid, withStyles, Dialog } from '@material-ui/core';
import { Page } from '../components';
import fetchImgs from '../api/gallery-api';

const GalleryPage = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgs, setImgs] = useState([]);
  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };
  useEffect(() => {
    fetchImgs().then((res) => {
      setImgs(res?.data);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page>
      <Grid container spacing={8}>
        {imgs && imgs.length
          ? imgs.map((image, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                className={classes.imgContainer}
              >
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                    image.attributes.img?.data?.attributes.formats.small.url
                  }
                  alt={`img-${index}`}
                  className={classes.img}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '15px',
                  }}
                  onClick={() =>
                    handleClickOpen(
                      process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                        image.attributes.img?.data?.attributes.formats.large.url
                    )
                  }
                />
              </Grid>
            ))
          : null}
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <img src={selectedImage} alt="Fullscreen" style={{ width: '100%' }} />
        </Dialog>
      </Grid>
    </Page>
  );
};

const styles = (theme) => ({
  imgContainer: {
    padding: '10px',
  },
  img: {
    width: '100%',
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '15px',
    '&:hover': {
      filter: 'brightness(80%)',
    },
  },
});

export default withStyles(styles)(GalleryPage);
