import { createContext, useState, useContext } from 'react';

export const FileContext = createContext();

export const useFile = () => {
  return useContext(FileContext);
};

export function FileProvider({ children }) {
  const [file, setFile] = useState(null);

  const value = {
    file,
    setFile,
  };

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
}
