import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { useTranslation } from 'react-i18next';
import fetchHeroCarouselImgs from '../api/hero-carousel-api';
import { useState, useEffect } from 'react';
import fallbackImg from '../assets/imgs/fallback.png';

const stylesButton = {
  root: {
    transition: 'background 0.3s ease-in-out, minWidth 0.3s ease-in-out',
    position: 'absolute',
    top: '50%',
    marginLeft: '20px',
    border: 'none',
    background: '#194681',
    color: '#fff',
    borderRadius: '20px',
    boxShadow: '2px 2px 7px 1px rgba(0, 0, 0, 0.07)',

    fontSize: '1rem',
    fontWeight: 500,
    minWidth: '120px',
    padding: '0 10px',
    '&:hover': {
      background: '#4682B4',
    },
  },
  icon: {
    fontSize: '1.5rem',
    transition: 'all 0.5s !important',
    '&:hover': {
      transition: 'all 0.5s !important',
    },
  },
};

export const CustomNextArrow = ({ classes, className, style, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIcon fontSize="small" classes={{ root: classes }} />
    </div>
  );
};

export const CustomPrevArrow = ({ classes, className, style, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIcon fontSize="small" classes={{ root: classes }} />
    </div>
  );
};

const HeroButton = withStyles(stylesButton)(({ text, link, classes }) => {
  const history = useHistory();
  function onClick() {
    history.push('/search');
  }
  return (
    <>
      <button className={classes.root} onClick={onClick}>
        {text} <TrendingFlatIcon classes={{ root: classes.icon }} />
      </button>
    </>
  );
});

const HeroImage = ({ classes, img }) => {
  return (
    <>
      <img className={classes.img} src={img}></img>
    </>
  );
};

const HeroCarousel = ({ classes }) => {
  const { t, i18n } = useTranslation();
  const [imgs, setImgs] = useState([]);
  const [isFallback, setIsFallback] = useState(true);
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    nextArrow: <CustomNextArrow classes={classes.next} />,
    prevArrow: <CustomPrevArrow classes={classes.prev} />,
  };

  useEffect(() => {
    fetchHeroCarouselImgs().then((res) => {
      if (res?.data?.length) {
        setImgs(res.data);
        setIsFallback(false);
      } else {
        setImgs([fallbackImg]);
        setIsFallback(true);
      }
    });
  }, []);

  return (
    <div className={classes.container}>
      <Slider {...settings}>
        {imgs.map((img, index) => (
          <div className={classes.slider}>
            <HeroImage
              classes={classes}
              img={
                isFallback
                  ? img
                  : process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                    img?.attributes?.image?.data?.attributes.formats.large.url
              }
            />
            {/* <HeroButton text={t('shop_now')} /> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

const styles = (theme) => {
  return {
    container: {
      boxShadow: '-3px -7px 37px -36px rgba(66, 68, 90, 1) !important',
    },
    slider: {
      overflow: 'hidden',
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        minHeight: 'auto',
      },
    },

    prev: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      borderRadius: '25px',
      '&:hover': {
        transition: 'all 0.5s !important',
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },

    next: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      border: 'none',
      borderRadius: '25px',
      '&:hover': {
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },
    img: {
      height: '100%',
      maxHeight: '750px',
      width: '100%',
      overflow: 'hidden',
      objectFit: 'cover',
      objectPosition: 'top',
      filter: 'brightness(95%)',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '600px',
      },
    },
  };
};

export default withStyles(styles)(HeroCarousel);
