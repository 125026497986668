import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { withStyles } from '@material-ui/core';

const ContactInfo = ({ classes }) => {
  return (
    <div className={classes.iconsContainer}>
      <div className={classes.item}>
        <FontAwesomeIcon icon={faPhone} size="xs" />
        <a href="tel:+97146084666" className={classes.text}>
          +971 48046700
        </a>
      </div>
      {/* <div className={classes.item}>
        <FontAwesomeIcon icon={faWhatsapp} size="xs" />
        <a href="https://wa.me/971565997237" target="_blank" className={classes.text}>+971 56 5997237</a>
      </div> */}
      <div className={classes.item}>
        <FontAwesomeIcon icon={faEnvelope} size="xs" />
        <a href="mailto:info@alliedmotorsparts.com" className={classes.text}>
          info@alliedmotorsparts.com
        </a>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  iconsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div, a': {
      color: '#fff',
      margin: '0 10px',
      '&:hover': {
        color: '#e0e0e0',
      },
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:first-child': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  text: {
    fontSize: '14px',
  },
});

export default withStyles(styles)(ContactInfo);
