import {
  Page,
  HeroCarousel,
  NewsCarousel,
  GalleryCarousel,
  SmallCarousel,
  SearchBar,
  PageFooter,
} from '../components';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
const HomePage = ({ classes }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Page hideFooter disableMaxWidth>
        <Grid container direction="column" justify="center">
          <Grid item md={12} className={classes.gridItem}>
            <SearchBar></SearchBar>
          </Grid>
          <Grid item xs={12}>
            <HeroCarousel></HeroCarousel>
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <NewsCarousel />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <GalleryCarousel />
          </Grid>
        </Grid>
      </Page>
      <Grid container justify="center" style={{ background: '#fff' }}>
        <Grid item xs={10} md={10} lg={10}>
          <SmallCarousel path={'oem'} />
        </Grid>
      </Grid>
      <PageFooter />
    </div>
  );
};
const styles = (theme) => {
  return {
    gridItem: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '1280px',
        margin: '0 auto',
        width: '100%',
      },
    },
    gridHeroItem: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
  };
};

export default withStyles(styles)(HomePage);
