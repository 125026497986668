import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { withStyles } from '@material-ui/core';
const SocialMediaLinks = ({ classes }) => {
  return (
    <div className={classes.iconsContainer}>
      <a
        href="https://www.facebook.com/profile.php?id=61563830302640&mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} size="xs" />
      </a>
      <a
        href="https://x.com/AlliedFzcodxb"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faXTwitter} size="xs" />
      </a>
      <a
        href="https://www.instagram.com/alliedmotorstrading?igsh=MWt5Ymp3MmIxMXY0Ng=="
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} size="xs" />
      </a>
      <a
        href="https://www.youtube.com/@alliedmotorstradingfzcobranch"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faYoutube} size="xs" />
      </a>
      <a
        href="http://www.linkedin.com/in/allied-motors-trading-fzco-branch-a9774b31b"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} size="xs" />
      </a>
    </div>
  );
};

const styles = (theme) => ({
  iconsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& a': {
      color: '#fff',
      margin: '0 5px',
      '&:hover': {
        color: '#e0e0e0',
      },
    },
  },
});

export default withStyles(styles)(SocialMediaLinks);
