import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Logo from '../assets/logo.jpg';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PageTabs from './PageTabs';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import { Button, Typography } from '@material-ui/core';
import NavMenu from './NavMenu';
import { height, width } from '@fortawesome/free-brands-svg-icons/fa42Group';
import SocialMediaLinks from './SocialMediaLinks';
import ContactInfoLine from './ContactInfoLine';

const PageHeader = ({
  classes,
  companyName,
  companyAddress,
  username,
  isLoggedIn,
  width,
  onLogin,
  onLogout,
  onSignUp,
  onUserData,
}) => {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langMenuAnchor, setLangMenuAnchor] = React.useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLangMenu = (event) => {
    setLangMenuAnchor(event.currentTarget);
  };

  const closeLangMenu = () => {
    setLangMenuAnchor(null);
  };

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    closeLangMenu();
  };
  const isMobile = isWidthDown('xs', width);
  const containMenu = isWidthDown('md', width);
  const hideSecondRow = isWidthDown('md', width);
  const buttonVariant = isMobile ? 'text' : 'contained';
  return (
    <>
      {!isMobile && (
        <div className={classes.headerTopBar}>
          <div
            style={{
              maxWidth: 1260,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div className={classes.headerContacts}>
              <ContactInfoLine />
              <Typography className={classes.headerContactsText}></Typography>
            </div>
            <div className={classes.headerIcons}>
              <SocialMediaLinks />
            </div>
          </div>
        </div>
      )}
      <div className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/logo/nav-logo.webp`}
              />
            </Link>
          </div>
          {!isMobile && (
            <PageTabs
              isLoggedIn={isLoggedIn}
              hideSecondRow
              hideFirstRow={containMenu}
              username={username}
              onOpenMenu={(anchor) => setMenuAnchor(anchor)}
            />
          )}

          <div className={classes.headerButtons}>
            <Menu
              classes={{
                paper: classes.menuPaper,
              }}
              anchorEl={anchorEl}
              elevation={5}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {!isLoggedIn ? (
                <div key="profileMenu">
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onSignUp();
                    }}
                  >
                    {' '}
                    {t('sign_up')}{' '}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onLogin();
                    }}
                  >
                    {' '}
                    {t('log_in')}{' '}
                  </MenuItem>
                </div>
              ) : (
                <div key="profileMenu">
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onUserData();
                    }}
                  >
                    {' '}
                    {t('profile')}{' '}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onLogout();
                    }}
                  >
                    {' '}
                    {t('log_out')}{' '}
                  </MenuItem>
                </div>
              )}
            </Menu>
          </div>
          {containMenu && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                anchor="right"
              >
                <div role="presentation" onKeyDown={toggleDrawer(false)}>
                  <Link
                    to="/home"
                    style={{ padding: '16px', textAlign: 'center' }}
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/logo/nav-logo.webp`}
                      alt="Logo"
                      style={{ maxWidth: '300px' }}
                    />
                  </Link>
                  <List>
                    <ListItem
                      button
                      component={Link}
                      to="/"
                      onClick={toggleDrawer(false)}
                    >
                      {t('home')}
                    </ListItem>
                    {isLoggedIn ? (
                      <ListItem
                        button
                        component={Link}
                        to="/basket"
                        onClick={toggleDrawer(false)}
                      >
                        {t('cart')}
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        component={Link}
                        to="/about"
                        onClick={toggleDrawer(false)}
                      >
                        {t('about')}
                      </ListItem>
                    )}
                    {isLoggedIn ? (
                      <ListItem
                        button
                        component={Link}
                        to="/orders"
                        onClick={toggleDrawer(false)}
                      >
                        Orders
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        component={Link}
                        to="/manuals"
                        onClick={toggleDrawer(false)}
                      >
                        Help
                      </ListItem>
                    )}
                    <ListItem
                      button
                      component={Link}
                      to="/search"
                      onClick={toggleDrawer(false)}
                    >
                      {t('search')}
                    </ListItem>
                    <ListItem
                      button
                      disabled={!isLoggedIn}
                      component={Link}
                      to="/dispatch"
                      onClick={toggleDrawer(false)}
                    >
                      {t('dispatch')}
                    </ListItem>
                    <ListItem
                      button
                      disabled={!isLoggedIn}
                      component={Link}
                      to="/balance"
                      onClick={toggleDrawer(false)}
                    >
                      {t('balance')}
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/offers"
                      onClick={toggleDrawer(false)}
                    >
                      {t('offers')}
                    </ListItem>
                    {!isLoggedIn ? (
                      <div key="profileMenu">
                        <MenuItem
                          onClick={() => {
                            toggleDrawer(false);
                            onSignUp();
                          }}
                        >
                          {' '}
                          {t('sign_up')}{' '}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            toggleDrawer(false);
                            onLogin();
                          }}
                        >
                          {' '}
                          {t('log_in')}{' '}
                        </MenuItem>
                      </div>
                    ) : (
                      <div key="profileMenu">
                        <MenuItem
                          onClick={() => {
                            toggleDrawer(false);
                            onUserData();
                          }}
                        >
                          {' '}
                          {t('profile')}{' '}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            toggleDrawer(false);
                            onLogout();
                          }}
                        >
                          {' '}
                          {t('log_out')}{' '}
                        </MenuItem>
                      </div>
                    )}
                  </List>
                </div>
              </Drawer>
            </>
          )}
        </Toolbar>
      </div>
      <div
        style={{
          backgroundColor: '#F0F0F0',
          width: '100%',
          maxWidth: '1264px',
        }}
      >
        {/* <PageTabs
          isLoggedIn={isLoggedIn}
          hideFirstRow
          //hideSecondRow={!hideSecondRow}
        /> */}
      </div>
      <NavMenu
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        isLoggedIn={isLoggedIn}
        onSignUp={onSignUp}
        onLogIn={onLogin}
        onUserProfile={onUserData}
        onLogout={onLogout}
      ></NavMenu>
    </>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;
  return {
    header: {
      backgroundColor: colors.header,
      width: '100%',
      maxWidth: '1380px',
      minHeight: '96px',
      marginLeft: '96px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: '72px',
        marginLeft: 0,
      },
    },
    headerTopBar: {
      position: 'relative',
      display: 'flex',
      backgroundColor: '#0032A0',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 32px',
      width: '100%',
      top: 0,
      height: '30px',
    },
    headerContactsText: {
      color: '#fff',
    },
    headerButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
    loginButton: {
      // border: `1px solid ${colors.loginButton.fontColor}`,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit,
      },
      // backgroundColor: colors.loginButton.default,
      // color: colors.loginButton.fontColor,
      // "&:hover": {
      //   backgroundColor: colors.loginButton.hovered
      // }
    },
    signUpButton: {
      marginLeft: theme.spacing.unit * 2,
      // backgroundColor: colors.signUpButton.default,
      // color: colors.signUpButton.fontColor,
      // "&:hover": {
      //   backgroundColor: colors.signUpButton.hovered
      // }
    },
    menuPaper: {
      transform: 'translateY(3rem) !important',
      borderRadius: '5px',
    },
    toolbar: {
      flex: '1 0 0px',
      justifyContent: 'space-around',
      gap: 'rem',
    },
    name: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing.unit,
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px',
      '& > img': {
        height: '55px',
        width: '55px',
      },
    },
    address: {
      color: theme.palette.custom.logo,
      fontWeight: 'normal',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    business: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing.unit,
      fontWeight: 'normal',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    loginText: {
      color: '#fff',
      marginLeft: theme.spacing.unit,
    },
    iconButtonLabel: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

export default withWidth()(withStyles(styles)(PageHeader));
