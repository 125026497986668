import { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import {
  Typography,
  Divider,
  Card,
  CardContent,
  Grid,
  Button,
} from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import fetchNews, { fetchNewsImage } from '../api/news-api';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const NewsCarousel = ({ width, classes }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [newsList, setNewsList] = useState([]);
  const [newsImage, setNewsImage] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  const getNewsList = async () => {
    const res = await fetchNews(i18n.language);
    if (res?.data && Array.isArray(res?.data))
      setNewsList(res?.data?.sort((a, b) => b.id - a.id).slice(0, 3));
    else {
      setNewsList([]);
    }
  };

  const getNewsImage = async () => {
    const res = await fetchNewsImage();
    if (res?.data )
      setNewsImage(res?.data[res?.data.length - 1]);
    else {
      setNewsImage([]);
    }
  };

  useEffect(() => {
    getNewsList();
    getNewsImage();
  }, [i18n.language]);

  const handleNavigeToDetailedPost = (id) => {
    history.push(`/news?id=${id}`);
  };
  const selectedImage = newsList[selectedItem]?.attributes.image?.data?.attributes?.formats?.large.url ||
   newsImage?.attributes?.img?.data?.attributes.formats.small.url
  
  return (
    <div style={{ padding: '20px', marginTop: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" align="center">
          {t('news')}
        </Typography>
        <a
          href="/news"
          style={{ borderBottom: '2px solid #194681', fontSize: '1.15rem' }}
        >
          {t('view_all')}
        </a>
      </div>

      <Divider style={{ marginBottom: '20px', background: '#000000aa' }} />
      <Grid container>
        <Grid item sm={12} xs={12} md={4} style={{ position: 'relative' }}>
          <div className={classes.slider}>
            {newsList && newsList.length
              ? newsList?.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                      padding: '0px',
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  >
                    <Card
                      style={{
                        backgroundColor:
                          selectedItem === index ? '#19468115' : 'white',
                      }}
                      className={classes.card}
                      onClick={() => setSelectedItem(index)}
                    >
                      <CardContent
                        style={{ position: 'relative', height: '100%' }}
                      >
                        <div className={classes.cardOverlay}>
                          <Button
                            style={{
                              marginTop: '10px',
                            }}
                            onClick={() => handleNavigeToDetailedPost(item.id)}
                          >
                            Read more
                          </Button>
                        </div>
                        <Typography variant="subtitle2" color="textSecondary">
                          {new Date(item.attributes.date).toLocaleDateString(
                            i18n.language === 'ru' ? 'ru-RU' : 'en-US',
                            {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            }
                          )}
                        </Typography>
                        <Typography variant="h6" color="#194681">
                          {item.attributes.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="pre"
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          <ReactMarkdown>
                            {item.attributes.summary?.slice(0, 200)}
                          </ReactMarkdown>
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                ))
              : null}
          </div>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          {
            newsList.length ?
          <div
            style={{
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div className={classes.imageOverlay} onMouseEnter={() => {}}>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{}}
                  onClick={() =>
                    handleNavigeToDetailedPost(newsList[selectedItem]?.id)
                  }
                >
                  Read more
                </Button>
              </div>
            </div>
            <img
              style={{
                width: '100%',
                height: '618px',
                maxHeight: '618px',
                maxWidth: '820px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              src={
                process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) + selectedImage
                  
              }
            ></img>
          </div> : null
          }
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (theme) => {
  return {
    slider: {
      height: '640px',
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        maxHeight: '670px',
        marginBottom: '20px',
      },
      '&.slick-track': {
        height: 'auto !important',
      },
    },
    card: {
      margin: '5px',
      padding: '0px 10px',
      position: 'relative',
      height: '200px',
      borderRadius: '0px',
      backdropFilter: 'blur(10px)',
      transition: '0.25s ease-in-out',
      '&:hover': {
        backgroundColor: 'rgba(0, 0,0, 0.11)',
        cursor: 'pointer',
        border: '1px solid #194681',
      },
    },
    cardOverlay: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      top: 0,
      left: 0,
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      backgroundColor: 'transparent',
      borderRadius: '10px',
      zIndex: 5,
      opacity: 0,
      '&:hover': {
        opacity: 1,
      },
    },
    imageOverlay: {
      position: 'absolute',
      width: '100%',
      maxWidth: '820px',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      width: '100%',
      height: '50px',
      padding: '0px 20px',
      backdropFilter: 'blur(10px)',
    },
    prev: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      borderRadius: '25px',
      transform: 'rotate(90deg)',
      '&:hover': {
        transition: 'all 0.5s !important',
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },

    next: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      border: 'none',
      borderRadius: '25px',
      transform: 'rotate(90deg)',
      '&:hover': {
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },
  };
};

export default withWidth()(withStyles(styles)(NewsCarousel));
