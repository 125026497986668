import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useSearch from '../utils/useSearch';
import { Button, Tabs, Tab, Typography } from '@material-ui/core';
import { SearchInput, ActionsGroup, SelectFine } from '../components';
import { useFile } from '../store/fileContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const deliveryTermLimit = [
  { title: '1', value: 1 },
  { title: '4', value: 4 },
  { title: '7', value: 7 },
  { title: '14', value: 14 },
  { title: '30', value: 30 },
  { title: '60', value: 60 },
  { title: '180', value: 180 },
];

const SearchBar = ({ classes, ...rest }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { setFile } = useFile();

  const {
    search,

    deliveryTerm,
    tab,
    isFileUploading,
    deliveryTariffList,
    currencyList,
    deliveryTariff,
    currency,
    openFileDialog,
    handleChangeCurrency,
    handleSearchInputChange,
    handleSearchInputSearch,
    handleTabChange,
    handleFileOpen,
    handleDeliveryTermChange,
    handleDeliveryTariffChange,
  } = useSearch(location, history);

  return (
    <div>
      <Tabs
        className={classes.tabs}
        value={tab}
        centered
        onChange={handleTabChange}
      >
        <Tab
          label={t('by_excel_file')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label={t('by_part_number')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>

      {tab === 0 ? (
        <div className={classes.searchContainerByFile}>
          <Button
            disabled={isFileUploading}
            className={classes.leftButton}
            variant="contained"
            onClick={handleFileOpen}
          >
            {t('upload')}
          </Button>
          <input
            ref={openFileDialog}
            type="file"
            hidden
            style={{ height: 0, width: 0 }}
            accept=".xlsx"
            onChange={(e) => {
              setFile(e.target.files[0]);
              history.push('/search');
            }}
          />

          <div className={classes.deliveryTermContainer}>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography className={classes.deliveryTermLabel} variant="body1">
                {t('max_days')}:
              </Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '5px 20px 0px 20px',
                  borderRadius: '2px',
                  height: '35px',
                }}
              >
                <SelectFine
                  items={deliveryTermLimit}
                  value={deliveryTerm}
                  onChange={handleDeliveryTermChange}
                  className="max-days"
                />
              </div>
            </span>
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                className={classes.deliveryTermLabel}
                variant="body1"
                style={{ paddingLeft: '10px' }}
              >
                Currency:
              </Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '5px 20px 0px 20px',
                  borderRadius: '2px',
                  height: '35px',
                }}
              >
                <SelectFine
                  items={currencyList}
                  value={currency}
                  /*label='Currency'*/
                  onChange={handleChangeCurrency}
                  className="max-days"
                />
              </div>
            </span>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                className={classes.deliveryTermLabel}
                variant="body1"
                style={{ paddingLeft: '10px' }}
              >
                Delivery:
              </Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '5px 20px 0px 20px',
                  borderRadius: '2px',
                  height: '35px',
                }}
              >
                <SelectFine
                  items={deliveryTariffList}
                  value={deliveryTariff}
                  onChange={handleDeliveryTariffChange}
                  className="max-days"
                />
              </div>
            </span>
          </div>

          <Button
            className={classes.rightButton}
            variant="contained"
            component="a"
            href="/quote_template.xlsx"
            download
          >
            {t('template')}
          </Button>
        </div>
      ) : (
        <div className={classes.searchContainer}>
          <SearchInput
            value={search}
            onChange={handleSearchInputChange}
            onSearch={handleSearchInputSearch}
          />
          <ActionsGroup className={classes.searchActionsGroup}>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                gap: '50px',
                lineHeight: '30px',
              }}
            >
              <span className={classes.actionCell}>
                <Typography
                  className={classes.deliveryTermLabel}
                  variant="body1"
                  style={{ paddingLeft: '10px' }}
                >
                  Delivery:
                </Typography>
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '5px 20px 0px 20px',
                    borderRadius: '2px',
                  }}
                >
                  <SelectFine
                    items={deliveryTariffList}
                    value={deliveryTariff}
                    /*label="Dispatch"*/
                    onChange={handleDeliveryTariffChange}
                  />
                </div>
              </span>
              <span className={classes.actionCell}>
                <Typography
                  className={classes.deliveryTermLabel}
                  variant="body1"
                  style={{ paddingLeft: '10px' }}
                >
                  Currency:
                </Typography>
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '5px 20px 1px 20px',
                    borderRadius: '2px',
                  }}
                >
                  <SelectFine
                    items={currencyList}
                    value={currency}
                    /*label='Currency'*/
                    onChange={handleChangeCurrency}
                  />
                </div>
              </span>
            </div>
          </ActionsGroup>
        </div>
      )}
    </div>
  );
};
/*
<SearchFilter
  filter={filter}
  disabled={!data.length}
  onFilterChange={handleFilterChange}
/>
*/

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  const searchContainer = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#315AB4',
    height: '72px',
    minHeight: '72px',
    marginBottom: theme.spacing.unit,
    padding: `0 ${theme.spacing.unit}px`,
  };
  return {
    tabs: {
      minHeight: 40,
    },
    tabRoot: {
      backgroundColor: colors.tabBackground,
      opacity: 1,
      minHeight: 40,
      '&.MuiTab-label': {
        transition: '0.1s',
      },
    },
    tabSelected: {
      backgroundColor: colors.tabSelectedBackground,
      color: '#fff',
      '&.MuiTab-label': {
        transition: '0.1s',
      },
    },
    searchContainer: {
      ...searchContainer,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        height: '120px',
        padding: '10px',
      },
    },
    searchContainerByFile: {
      ...searchContainer,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '220px',
        padding: '10px',
      },
    },
    deliveryTermContainer: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing.unit,
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: theme.spacing.unit,
      },
    },
    deliveryTermLabel: {
      color: colors.deliveryTermLabel,
      marginRight: theme.spacing.unit,
    },
    actionsGroup: {
      justifyContent: 'space-between',
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    actionCell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    searchActionsGroup: {
      justifyContent: 'space-between',
      marginBottom: 0,
    },
    basketButton: {},
    excelButton: {},
    tableContainer: theme.tableContainer,
    nextContainer: {
      marginLeft: theme.spacing.unit,
    },
    downContainer: {
      marginTop: theme.spacing.unit,
    },
    leftButton: {
      marginLeft: theme.spacing.unit,
    },
    rightButton: {
      marginRight: theme.spacing.unit,
    },
    whiteControl: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&:hover .MuiOutlinedInput-input': {
        color: 'red',
      },
    },
  };
};

export default withStyles(styles)(SearchBar);
