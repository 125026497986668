import axios from 'axios';

const STRAPI_URL = process.env.REACT_APP_STRAPI_API_URL;

const fetchHeroCarouselImgs = async (lang) => {
  try {
    const response = await axios.get(
      `${STRAPI_URL}/carousel-images?populate=image`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error while fetching carousel images', error);
  }
};

export default fetchHeroCarouselImgs;
