import React, { useEffect, useState, useRef } from 'react';
import { Page, SearchBar } from '../components';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  TextField,
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import MailIcon from '@material-ui/icons/Mail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import sendForm from '../api/email-api';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ScheduleIcon from '@material-ui/icons/Schedule';
const ContactPage = ({ classes }) => {
  const { t } = useTranslation();
  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    message: '',
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = 'First Name is required';
    if (!formData.lastName) errors.lastName = 'Last Name is required';
    if (!formData.phoneNumber) errors.phoneNumber = 'Phone Number is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.message) errors.message = 'Message is required';

    // Add more validation logic here (e.g., email format, phone format)

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendForm(form).then(
        (result) => {
          if (result.text === 'OK') setSuccess(true);
        },
        (error) => {
          console.log(error);
          setError(true);
        }
      );
    }
  };

  return (
    <Page>
      <Grid alignItems="center" className={classes.root} container spacing={8}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            style={{
              margin: '30px auto',
              textAlign: 'center',
              color: '#0032A0',
              fontWeight: '600',
            }}
          >
            {t('get_in_touch')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.infoPanel}>
            <Typography style={{ marginTop: '20px' }} variant="h5">
              {t('contact_us')}
            </Typography>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <PhoneForwardedIcon fontSize="large" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '10px',
                }}
              >
                <Typography style={{ color: '#fff' }} variant="body1">
                  +971 48046700
                </Typography>
              </div>
            </div>

            <Typography style={{ marginTop: '20px' }} variant="h5">
              {t('email_address')}
            </Typography>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <MailIcon fontSize="large" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '10px',
                }}
              >
                <Typography style={{ color: '#fff' }} variant="body1">
                  {' '}
                  Info@alliedmotorsparts.com
                </Typography>
              </div>
            </div>

            <Typography style={{ marginTop: '20px' }} variant="h5">
              {t('office_location')}
            </Typography>

            <div style={{ display: 'flex', marginTop: '20px' }}>
              <AccountBalanceIcon fontSize="large" />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '10px',
                }}
              >
                <Typography style={{ color: '#fff' }} variant="body1">
                  Allied Motors Trading FZCO (Branch)
                </Typography>
                <Typography style={{ color: '#fff' }} variant="body1">
                  Warehouse: RA08HA01 & HA02
                </Typography>
                <Typography style={{ color: '#fff' }} variant="body1">
                  PO Box 7211, Jebel Ali Free Zone - Dubai, UAE.
                </Typography>
              </div>
            </div>
            <Typography style={{ marginTop: '20px' }} variant="h5">
              Office Timings
            </Typography>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <ScheduleIcon fontSize="large" />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '10px',
                }}
              >
                <Typography style={{ color: '#fff' }} variant="body1">
                  Mon – Sat: 8:30 am to 5:00 pm
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        {/* Contact Form */}
        <Grid item xs={12} md={8} className={classes.form}>
          <Typography
            variant="h5"
            style={{ padding: '20px', fontWeight: '500' }}
          >
            {t('enquire_now')}
          </Typography>

          <form
            className={classes.innerForm}
            ref={form}
            onSubmit={handleSubmit}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={6} xs={6}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('firstName')}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  placeholder={t('firstName')}
                  InputProps={{
                    name: 'first_name',
                  }}
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  helperText={formErrors.firstName}
                  error={Boolean(formErrors.firstName)}
                  className={classes.formField}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('lastName')}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  InputProps={{
                    name: 'last_name',
                  }}
                  placeholder={t('lastName')}
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                  helperText={formErrors.lastName}
                  error={Boolean(formErrors.lastName)}
                  className={classes.formField}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('phoneNumber')}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Phone Number"
                  InputProps={{
                    name: 'phone_number',
                  }}
                  placeholder={t('phoneNumber')}
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                  helperText={formErrors.phoneNumber}
                  error={Boolean(formErrors.phoneNumber)}
                  className={classes.formField}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="p" className={classes.textLabel}>
                  Email*
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Email"
                  InputProps={{
                    name: 'email',
                  }}
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  helperText={formErrors.email}
                  error={Boolean(formErrors.email)}
                  className={classes.formField}
                />
              </Grid>
            </Grid>
            <Typography variant="p" className={classes.textLabel}>
              {t('message')}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label="Write Your Message"
              InputProps={{
                name: 'message',
              }}
              placeholder={t('message_placeholder')}
              multiline
              rows={4}
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              helperText={formErrors.message}
              error={Boolean(formErrors.message)}
              className={classes.formField}
            />
            <Button
              variant="contained"
              type="submit"
              className={classes.button}
              style={{ backgroundColor: success ? 'green' : error && 'red' }}
            >
              {error ? (
                <ErrorIcon />
              ) : success ? (
                <CheckCircleIcon />
              ) : (
                t('submit_form')
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.mapContainer}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d904.2614774546198!2d55.0591316!3d24.9645526!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f0d1d9b32de77%3A0xc3e2342facf3cfbf!2sAllied%20Motors%20Trading%20FZCO%20(Branch)!5e0!3m2!1sen!2spl!4v1721939610380!5m2!1sen!2spl"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
};
const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {},
  },
  formField: {
    margin: '10px',
    //border: '1px solid #000000AA',
    '& *': {
      borderRadius: '10px',
      margin: '0 10px',
    },
    '& > label': {
      marginLeft: '30px',
    },
  },

  infoPanel: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    justifyContent: 'center',
    backgroundColor: '#0032A0',
    padding: '30px 30px 30px 30px',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: '-3px -4px 26px -12px rgba(66, 68, 90, 1)',
    '& > *': {
      color: '#fff',
    },
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(0)',
      marginBottom: '30px',
    },
  },

  form: {
    padding: '30px 30px 30px 90px',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '-3px -4px 26px -12px rgba(66, 68, 90, 1)',
    [theme.breakpoints.up('md')]: {
      height: '560px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '30px 30px 30px 30px',
    },
  },
  innerForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 30px 30px 60px',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#0032A0',
    color: '#fff',
    width: '135px',
    height: '40px',
    marginLeft: '30px',
    marginTop: '20px',
    borderRadius: '10px',
    '&:hover': {
      backdropFilter: 'brightness(10%)',
    },
  },
  textLabel: {
    fontSize: '14px',
    marginBottom: '0px',
    marginLeft: '30px',
  },
  mapContainer: {
    margin: '60px 0',
  },
});

export default withStyles(styles)(ContactPage);
