import React, { PureComponent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';

class Captcha extends PureComponent {
  render() {
    const { captcha, textFieldClassName, value, onChange } = this.props;

    return (
      <FormControl fullWidth margin="normal">
        <img src={`data:image/svg+xml,${encodeURIComponent(captcha)}`} />
        <TextField
          name="captcha"
          className={textFieldClassName}
          margin="dense"
          variant="outlined"
          placeholder="ENTER TEXT FROM AN IMAGE ABOVE"
          value={value}
          onChange={onChange}
        />
      </FormControl>
    );
  }
}

export default Captcha;
