import React from 'react';
import {
  Menu,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Paper,
  Grow,
  Popper,
  Grid,
  Button,
  withStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const NavMenu = ({
  classes,
  anchorEl,
  onClose,
  isLoggedIn,
  onSignUp,
  onLogIn,
  onUserProfile,
  onLogout,
}) => {
  const { t } = useTranslation();
  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      role={undefined}
      placement="bottom-end"
      transition
      disablePortal
      style={{ zIndex: 1301, marginTop: '40px' }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            borderRadius: '5px',
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList id="menu-list-grow">
                <div className={classes.menuItemTop}>
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      className={classes.centeredCell}
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      <Button
                        className={classes.userActionButton}
                        onClick={
                          isLoggedIn
                            ? () => {
                                onUserProfile();
                                onClose();
                              }
                            : () => {
                                onLogIn();
                                onClose();
                              }
                        }
                      >
                        {isLoggedIn ? t('profile') : t('login')}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      className={classes.centeredCell}
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      <Button
                        className={classes.userActionButton}
                        onClick={
                          isLoggedIn
                            ? () => {
                                onLogout();
                                onClose();
                              }
                            : () => {
                                onSignUp();
                                onClose();
                              }
                        }
                      >
                        {isLoggedIn ? t('logout') : t('register')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                <Typography
                  variant="h6"
                  style={{
                    marginLeft: '12px',
                    marginTop: '10px',
                    marginBottom: '5px',
                  }}
                >
                  Your account
                </Typography>
                <MenuItem
                  dense
                  className={classes.menuItem}
                  onClick={onClose}
                  value={'search'}
                  to="/search"
                  component={Link}
                >
                  {t('search')}
                </MenuItem>
                <MenuItem
                  dense
                  className={classes.menuItem}
                  onClick={onClose}
                  value={'basket'}
                  label={t('cart')}
                  to="/basket"
                  component={Link}
                >
                  {t('cart')}
                </MenuItem>
                <MenuItem
                  dense
                  className={classes.menuItem}
                  onClick={onClose}
                  disabled={!isLoggedIn}
                  value={'orders'}
                  to="/orders"
                  component={Link}
                >
                  {t('orders')}
                </MenuItem>
                <MenuItem
                  dense
                  className={classes.menuItem}
                  onClick={onClose}
                  disabled={!isLoggedIn}
                  value={'dispatch'}
                  label={t('dispatch')}
                  to="/dispatch"
                  component={Link}
                >
                  {t('dispatch')}
                </MenuItem>
                <MenuItem
                  dense
                  className={classes.menuItem}
                  onClick={onClose}
                  disabled={!isLoggedIn}
                  value={'balance'}
                  to="/balance"
                  component={Link}
                >
                  {t('balance')}
                </MenuItem>
                <MenuItem
                  dense
                  className={classes.menuItem}
                  onClick={onClose}
                  value={'offers'}
                  to="/offers"
                  component={Link}
                >
                  {t('offers')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;

  return {
    menuItem: {
      fontSize: '14px',
      '&&&.MuiListItem-dense': {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    menuItemTop: {
      width: 240,
      '&&&.MuiListItem-button:hover': {
        backgroundColor: '#fff',
      },
      paddingTop: 5,
      height: 50,
      borderBottom: '1px solid #00000012',
    },
    userActionButton: {
      backgroundColor: theme.palette.custom.loginDialog.button.default,
      color: theme.palette.custom.loginDialog.button.fontColor,
      width: 80,
      height: 32,
      '&:hover': {
        backgroundColor: theme.palette.custom.loginDialog.button.hovered,
      },
    },
    centeredCell: {
      display: 'flex',
      justifyContent: 'center',
      '&:first-child': {
        borderRight: '1px solid #00000012',
      },
    },
  };
};

export default withStyles(styles)(NavMenu);
