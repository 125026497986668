import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import color from '@material-ui/core/colors/amber';
import classNames from 'classnames';
import NavMenu from './NavMenu';
import zIndex from '@material-ui/core/styles/zIndex';
import { select } from '../api/basket-api';
import { Badge } from '@material-ui/core';
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
const PageTabs = (props) => {
  const { i18n, t } = useTranslation();
  const { classes, width, isLoggedIn, onOpenMenu, username } = props;
  const isScrollable = isWidthDown('sm', width);
  const [currentTab, setCurrentTab] = useState('');
  const [cartItemsCount, setCartItemsCount] = useState(null);
  const productListTabs = [
    // 'search',
    // 'basket',
    // 'orders',
    // 'dispatch',
    // 'balance',
    // 'offers',
    'profile',
  ];

  useEffect(() => {
    setCurrentTab(window.location.pathname.split('/')[1]);
  }, [currentTab]);

  const handleChange = (value) => {
    if (value !== currentTab) {
      setCurrentTab(value);
    }
  };

  if (isLoggedIn) {
    select().then((result) => {
      setCartItemsCount(result.length);
    });
  }

  return (
    <div className={classes.container}>
      {cartItemsCount > 0 && (
        <Badge
          className={classes.cartBadge}
          badgeContent={cartItemsCount}
          color="secondary"
        />
      )}
      {!props.hideFirstRow && (
        <Tabs
          value={currentTab}
          onChange={handleChange}
          style={{ maxWidth: '100%', overflow: 'hidden' }}
          //variant={isScrollable ? 'scrollable' : 'standard'}
        >

          {isLoggedIn ?
             <Tab
             className={classes.tab}
             classes={{
               selected: classes.tabSelected,
               wrapper: classes.tabWrapper,
             }}
             value={'search'}
             label={t('search')}
             to="/search"
             component={Link}
           />
          : 
          <Tab
          className={classes.tab}
          classes={{
            selected: classes.tabSelected,
            wrapper: classes.tabWrapper,
          }}
          value={''}
          label={t('home')}
          to="/"
          component={Link}
        />
          }
         
          {isLoggedIn ? (
            <Tab
              className={classes.tab}
              classes={{
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              value={'basket'}
              label={t('cart')}
              to="/basket"
              component={Link}
            ></Tab>
          ) : (
            <Tab
              className={classes.tab}
              classes={{
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              value={'about'}
              label={t('about')}
              to="/about"
              component={Link}
            />
          )}
          {isLoggedIn ? (
            <Tab
              className={classes.tab}
              classes={{
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              value={'orders'}
              label={'Orders'}
              to="/orders"
              component={Link}
            />
          ) : (
            <Tab
              className={classes.tab}
              classes={{
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              value={'manuals'}
              label="Help"
              to="/manuals"
              component={Link}
            />
          )}
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            disabled={!isLoggedIn}
            value={'dispatch'}
            label={t('dispatch')}
            to="/dispatch"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            disabled={!isLoggedIn}
            value={'balance'}
            label={t('balance')}
            to="/balance"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'offers'}
            label={t('offers')}
            to="/offers"
            component={Link}
          />

          {/* <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'news'}
            label={t('news').split(' ')[0]}
            to="/news"
            component={Link}
          /> */}
          {/* <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'gallery'}
            label={t('gallery')}
            to="/gallery"
            component={Link}
          /> */}
          {/* <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'contact'}
            label={t('contact')}
            to="/contact"
            component={Link}
          /> */}
          <div className={classes.profileButtonContainer}>
            {isLoggedIn && (
              <span className={classes.profileButtonHello}>{`${t(
                'hello'
              )}, ${username}`}</span>
            )}
            <Tab
              className={classes.tab}
              selected={productListTabs.some((page) => page === currentTab)}
              classes={{
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              onClick={(e) => onOpenMenu(e.currentTarget)}
              label={t('profile')}
              component={Button}
            ></Tab>
          </div>
        </Tabs>
      )}

      {!props.hideSecondRow && (
        <Divider
          light
          style={{
            height: '5px',
            background: '#fff',
            width: '100%',
            minWidth: '460px',
            opacity: '1',
          }}
        />
      )}

      <motion.div
        initial="hidden"
        animate={
          productListTabs.some((page) => page === currentTab)
            ? 'visible'
            : 'hidden'
        }
      >
        {productListTabs.some((page) => page === currentTab) &&
          !props.hideSecondRow && (
            <>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                variant={isScrollable ? 'scrollable' : 'standard'}
              >
                <Tab
                  className={classNames(classes.innerTab, classes.tabDividers)}
                  classes={{
                    selected: classes.innerTabSlected,
                    wrapper: classes.tabWrapper,
                  }}
                  value={'search'}
                  label={t('search')}
                  to="/search"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.innerTab, classes.tabDividers)}
                  classes={{
                    selected: classes.innerTabSlected,
                    wrapper: classes.tabWrapper,
                  }}
                  value={'basket'}
                  label={t('cart')}
                  to="/basket"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.innerTab, classes.tabDividers)}
                  classes={{
                    selected: classes.innerTabSlected,
                    wrapper: classes.tabWrapper,
                  }}
                  disabled={!isLoggedIn}
                  value={'orders'}
                  label={t('orders')}
                  to="/orders"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.innerTab, classes.tabDividers)}
                  classes={{
                    selected: classes.innerTabSlected,
                    wrapper: classes.tabWrapper,
                  }}
                  disabled={!isLoggedIn}
                  value={'dispatch'}
                  label={t('dispatch')}
                  to="/dispatch"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.innerTab, classes.tabDividers)}
                  classes={{
                    selected: classes.innerTabSlected,
                    wrapper: classes.tabWrapper,
                  }}
                  disabled={!isLoggedIn}
                  value={'balance'}
                  label={t('balance')}
                  to="/balance"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.innerTab, classes.tabDividers)}
                  classes={{
                    selected: classes.innerTabSlected,
                    wrapper: classes.tabWrapper,
                  }}
                  value={'offers'}
                  label={t('offers')}
                  to="/offers"
                  component={Link}
                />
              </Tabs>
            </>
          )}
      </motion.div>
    </div>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#E4E5E6',
      color: '#fff',
      flexDirection: 'column',
      overflow: 'auto',
      position: 'relative',
      padding: '5px 10px',
      justifyContent: 'center',
      //marginTop: '2px',
      [theme.breakpoints.down('sm')]: {},
    },
    profileButtonContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    profileButtonHello: {
      color: '#000000aa',
      position: 'absolute',
      textAlign: 'center',
      zIndex: 6,
      fontSize: '12px',
      fontWeight: '500',
    },
    button: {
      position: 'absolute',
      top: '9px',
      right: '24px',
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    tabWrapper: {
      '& > *': {
        fontSize: '1rem ',
      },
    },
    tab: {
      opacity: 1,
      marginLeft: '4px',
      color: '#000000bb',
      borderRadius: '8px',
      minWidth: '140px',
      textTransform: 'none !important',
      // borderLeft: '1px solid white',
      // borderRight: '1px solid white',
      '&.MuiTab-wrapper': {},
      '&:last-child': {
        borderRight: 'none',
      },
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:hover': {
        color: '#000000bb',
        backgroundColor: '#F2F2F2',
        transition: 'none !important',
      },
      '&&.MuiTab-label a': {
        transition: 'none',
      },
      '&&.MuiTab-label span': {
        transition: 'none',
      },
    },
    innerTab: {
      opacity: 1,
      color: '#000000aa',
      textTransform: 'none !important',
      // borderLeft: '1px solid white',
      // borderRight: '1px solid white',
      '&:last-child': {
        borderRight: 'none',
      },
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:hover': {
        color: '#fff',
        borderBottom: '1px solid #0032A0',
        backgroundColor: '#0032A0bb',
        transition: '0.1s',
      },
      '&.MuiTab-label': {
        transition: '0.1s',
      },
    },
    tabDividers: {
      '&:not(:last-child)::after': {
        content: '""',
        display: 'inline-block',
        backgroundColor: '#ccc',
        height: '100%',
        width: '2px',
        position: 'absolute',
        right: 0,
        top: '0',
      },
    },
    tabSelected: {
      backgroundColor: 'transparent',
      borderRadius: '1px',
      color: '#000000bb',
      borderRadius: '8px',
      backgroundColor: '#F2F2F2',
      textDecoration: 'underline',
    },
    innerTabSlected: {
      backgroundColor: '#0032A0',
      borderRadius: '1px',
      color: '#fff',
      textDecoration: 'underline',
    },
    cartBadge: {
      zIndex: 1000,
      position: 'absolute',
      backgroundColor: 'red',
      left: '200px',
      top: '17px',
    },
  };
};

export default withWidth()(withStyles(styles)(PageTabs));
