import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { saveExcel } from '../utils/excel';
import { Button } from '@material-ui/core';
import {
  Page,
  BalanceTable,
  BalanceTotalTable,
  ActionsGroup,
  DatePicker,
  BalanceTransferDialog,
  BalanceTopupDialog,
} from '../components';
import * as invoiceApi from '../api/invoice-api';
import * as balanceApi from '../api/balance-api';
import { APP_DATE_FORMAT, SERVER_DATE_FORMAT } from '../utils/date';
import { getCookie } from '../utils/cookies';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BalancePage = ({ classes }) => {
  const [data, setData] = useState([]);
  const [balanceData, setBalanceData] = useState({});
  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [isBalTransDlgOpen, setBalTransDlgOpen] = useState(false);
  const [isBalTopupDlgOpen, setBalTopupDlgOpen] = useState(false);
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const { t, i18n } = useTranslation();

  const columns = useMemo(
    () => [
      { name: t('date'), width: 10 },
      { name: t('document'), width: 10 },
      { name: t('reference') },
      { name: t('debit'), width: 12 },
      { name: t('credit'), width: 12 },
      { name: t('weight_kg'), width: 11 },
      { name: t('volume_kg'), width: 11 },
    ],
    [i18n.language]
  );

  const outputData = useMemo(
    () =>
      data.map((value) => [
        moment(value.aDate).format(APP_DATE_FORMAT),
        value.document,
        value.aNumber,
        value.debet,
        value.credit,
        value.grossKG,
        value.volumeKG,
      ]),
    [data]
  );

  async function fetchData() {
    const result = await invoiceApi.invoices({
      fromDate: startDate ? startDate.format(SERVER_DATE_FORMAT) : null,
      tillDate: endDate ? endDate.format(SERVER_DATE_FORMAT) : null,
    });
    setData(result);
  }

  useEffect(() => {
    if (isLoggedIn) fetchData();
  }, []);

  async function fetchBalanceData() {
    const result = await balanceApi.totals();
    setBalanceData(result);
  }

  useEffect(() => {
    if (isLoggedIn) fetchBalanceData();
  }, []);

  function handleBalanceExport() {
    saveExcel('Balance', columns, outputData);
  }

  async function handleInvoiceExport(invoice) {
    try {
      const details = await invoiceApi.invoiceDetails(invoice.id);
      if (!details.length) return;
      const columns = Object.keys(details[0]).map((key) => ({
        name: key,
      }));
      const data = details.map((item) => Object.values(item));

      saveExcel(`Invoice_${invoice.aNumber}`, columns, data);
    } catch {
      alert(t('cannot_export_invoice'));
    }
  }

  function handleFilterData() {
    fetchData();
  }

  function toggleBalTransDlg() {
    //setBalTransDlgOpen(!isBalTransDlgOpen);
    window.location.href = '/bank';
  }

  function toggleBalTopupDlg() {
    setBalTopupDlgOpen(!isBalTopupDlgOpen);
  }

  return (
    <Page>
      {!isLoggedIn && <Redirect to="/search" />}
      <div className={classes.centerContainer}>
        <BalanceTotalTable data={balanceData} />
      </div>
      <ActionsGroup className={classes.actionsGroup}>
        <div className={classes.balanceActionsLeft}>
          <DatePicker
            className={classes.datePicker}
            classes={{
              input: classes.datePickerInput,
            }}
            label={t('start_date')}
            value={startDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={setStartDate}
          />
          <DatePicker
            className={classes.datePicker}
            classes={{
              input: classes.datePickerInput,
            }}
            label={t('end_date')}
            value={endDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={setEndDate}
          />
          <Button
            variant="contained"
            className={classes.showButton}
            onClick={handleFilterData}
          >
            {t('show')}
          </Button>
          <Button
            variant="contained"
            className={classes.exportButton}
            onClick={handleBalanceExport}
          >
            {t('export')}
          </Button>
        </div>
        {balanceData && balanceData.topupReference /* === 'AAAA'*/ && (
          <Button
            variant="contained"
            color="primary"
            className={classes.topUpBalanceButton}
            onClick={toggleBalTopupDlg}
          >
            {t('topup_online')}
          </Button>
        )}
        <a href="/bank">
          <Button variant="contained" className={classes.bankTransferButton}>
            {t('bank_transfer')}
          </Button>
        </a>
      </ActionsGroup>
      <div className={classes.tableContainer}>
        <BalanceTable
          columns={columns}
          data={outputData}
          originalData={data}
          onInvoiceExport={handleInvoiceExport}
        />
      </div>
      <BalanceTransferDialog
        open={isBalTransDlgOpen}
        onClose={toggleBalTransDlg}
      />
      <BalanceTopupDialog
        open={isBalTopupDlgOpen}
        onClose={toggleBalTopupDlg}
        topupId={balanceData.topupId}
      />
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.balancePage;
  const balanceActionsItem = {
    marginRight: theme.spacing.unit,
  };
  return {
    actionsGroup: {
      justifyContent: 'flex-end',
    },
    centerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '24px',
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 24px',
    },
    balanceActionsLeft: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'center',
    },
    balanceActionsRight: {
      flex: '1 0 0px',
      display: 'flex',
      justifyContent: 'flex-end',
      height: '36px',
    },
    balanceActionsItem,
    datePicker: {
      ...balanceActionsItem,
      maxWidth: '130px',
    },
    datePickerInput: {
      height: '36px',
    },
    showButton: {
      marginRight: theme.spacing.unit,
    },
    topUpBalanceButton: {
      ...balanceActionsItem,
      marginRight: theme.spacing.unit,
    },
    bankTransferButton: {
      ...balanceActionsItem,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    exportButton: {},
    tableContainer: theme.tableContainer,
  };
};

export default withStyles(styles)(BalancePage);
