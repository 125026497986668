import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';

if (!localStorage.getItem('selectedLanguage')) {
  localStorage.setItem('selectedLanguage', 'en');
}

const resources = {
  en: {
    translation: {
      //NAVIGATION
      home: 'Home',
      about: 'About Us',
      product_list: 'Product List',
      gallery: 'Gallery',
      contact: 'Contact',
      hello: 'Hello',

      search: 'Search',
      cart: 'Cart',
      orders: 'Orders',
      dispatch: 'Dispatch',
      balance: 'Balance',
      offers: 'Offers',

      sign_up: 'Sign Up',
      log_in: 'Log In',
      log_out: 'Log Out',
      profile: 'Account',
      //HOME PAGE
      shop_now: 'Shop Now',
      news: 'News & articles',
      oem_brands: 'Brands we offer',
      aftermarket_brands: 'Aftermarket Brands',
      view_all: 'View All',

      //footer
      quick_links: 'Quick Links',
      follow_us: 'Follow Us on',
      my_account: 'My Account',
      search_product: 'Search Products',
      shopping_cart: 'Shopping Cart',
      resources: 'Resources',
      location: 'Office location',
      faq: 'FAQ',
      //about page
      companyProfileHeading: 'Company Profile',
      whyChooseUsHeading: 'Why choose us',
      qualityPolicyHeading: 'Quality policy',
      item1: 'Timing Belt, Timing Kit, Timing Chain',
      item2: 'Rib Belt, Fan Belt',
      item3: 'Piston, Liners',
      item4: 'Piston ring',
      item5: 'Valve seal, Oil seal',
      item6: 'Suspension parts',
      item7: 'C.V. joint boot',
      item8: 'Valve cover gasket, Oil Pan',
      item9: 'Shock absorber boot',
      item10: 'Brake pad, brake shoe',
      item11: 'Air filters, Oil Filters, Fuel Filters',
      item12: 'Main Bearing, Con Bearing',
      item13: 'Engine Valve, Valve Guide',
      item14: 'Engine Bearing, Wheel Bearing',

      //contact page
      get_in_touch: 'Get in touch',
      contact_us: 'Contact Us',
      email_address: 'Email Address',
      office_location: 'Office Location',
      enquire_now: 'Enquire Now',
      firstName: 'First Name*',
      lastName: 'Last Name*',
      phoneNumber: 'Phone Number*',
      message: 'Message*',
      message_placeholder: 'Write Your Message',
      submit_form: 'Submit',

      // User data fields
      user_name: 'NAME',
      user_country: 'COUNTRY',
      user_zip: 'POST CODE',
      user_state: 'STATE/PROVINCE',
      user_city: 'CITY',
      user_street: 'STREET',
      user_building: 'BUILDING',
      user_flat: 'FLAT/OFFICE',
      user_phone: 'PHONE',
      user_email: 'EMAIL',
      user_tax: 'TAX NUMBER',
      user_currency: 'CURRENCY',
      user_contact_person: 'CONTACT PERSON',
      user_contact_email: 'EMAIL',
      user_contact_phone: 'CONTACT PHONE',
      save: 'SAVE',

      //verify email
      verify_email_processing: 'Verifying...',
      verify_email_heading: 'Email verification',
      verify_email_text: 'We have sent you an email to',
      verify_email_text2:
        'Please, check your inbox and click the link in the message',
      close_text: 'Close',

      // Search page
      by_part_number: 'By Part Number',
      by_excel_file: 'By Excel File',
      to_cart: 'To Cart',
      export: 'Export',
      upload: 'Upload',
      max_days: 'Max days',
      template: 'Template',
      demo_prices_log_in:
        'You see DEMO prices. To get actual ones, kindly login',
      demo_prices_complete_registration:
        'You see DEMO prices. To get actual ones, kindly deposit USD500 to complete registration',
      for_order: 'To Order',
      error_quotation: 'Cannot process quotation due to error',
      quotation_differs:
        'Some items you can purchase with new lead times, prices or quantities',
      where_to_dispatch: 'Delivery',

      // Balance page
      cannot_export_invoice: 'Cannot export invoice due to the error',
      start_date: 'Start Date',
      end_date: 'End Date',
      show: 'Show',
      topup_online: 'TopUp Online',
      bank_transfer: 'Bank Transfer',
      document: 'document',
      debit: 'Debit',
      credit: 'Credit',

      // Basket page
      cannot_select_item: 'Cannot select item(s) due to the error',
      cannot_change_quantity: 'Cannot change quantity due to the error',
      cannot_apply_changes: 'Cannot apply changes due to the error',
      cannot_order_selected: 'Cannot order selected due to the error',
      cannot_delete_selected: 'Cannot delete selected due to the error',
      cannot_change_ref: 'Cannot change reference due to the error',
      order: 'Order',
      remove: 'Remove',
      order_accepted:
        'Your order № {{orderId}} in amount of {{currency}}{{amount}} is accepted.',
      order_not_accepted:
        'Your order not accepted due to the error (code = {{orderId}})',
      activate_account: 'Please contact us to activate your account',
      insufficient_funds: 'Insufficient funds, please check your balance',
      no_items: 'No items for order',
      incorrect_items: 'There are incorrect items in the cart',
      basket_log_in: 'To place orders, kindly login',
      basket_complete_registration:
        'To place orders, kindly deposit USD500 to complete registration',

      // Orders page
      part_number: 'Part number',
      order_number: 'Order number',
      reference: 'Reference',
      all: 'all',

      // Password reset page
      submit: 'Submit',
      new_password: 'NEW PASSWORD',

      // Shipments page
      cannot_update_shipment_date:
        'Cannot update shipment date due to the error',

      // Balance topup dialog
      amount_with_currency: 'AMOUNT ({{currency}})',
      charge: 'CHARGE: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'TOP UP',

      // Balance total table
      balance_with_currency: 'Balance ({{currency}})',
      in_orders: 'In Orders',
      in_cart: 'In Cart',
      pay_for_cart: 'Pay for Cart',
      ready_to_ship: 'Ready to Ship',
      pay_to_ship: 'Pay to Ship',

      // Basket table
      brand: 'Brand',
      price_with_currency: 'Price ({{currency}})',
      quantity: 'Quantity',
      total_with_currency: 'Total ({{currency}})',
      weight_kg: 'Weight (kg)',
      booking: 'Warehouse',
      delivery: 'Delivery',
      description: 'Description',
      duplication: 'Duplication',

      // Basket total table
      to_order: 'To Order',
      available_for_order: 'Available for Order',
      pay_for_order: 'Pay for Order',
      items: 'Items',

      // Captcha
      captcha_field_placeholder: 'ENTER TEXT FROM AN IMAGE ABOVE',

      //Catalog page
      offers_log_in: 'To download offers, kindly login',
      offers_complete_registration:
        'To download offers, kindly deposit USD500 to complete registration',

      // Catalog table
      offer: 'Offer',
      download: 'Download',
      lines: 'Lines',
      updated: 'Updated',

      //Email verified page
      email_verified: 'Your email has been verified',

      // Log In dialog
      email: 'email',
      password: 'password',
      forgot_password: 'Forgot password?',
      reset_link_sent: 'Reset link is sent to your email',

      // Orders table
      date: 'Date',
      order_price: 'Order Price',
      sale_price: 'Sale Price',
      ordered: 'Ordered',
      purchased: 'Purchased',
      shipped: 'Shipped',
      refused: 'Refused',
      state_date: 'State Date',

      // Search input
      search_input_placeholder: 'Search...',

      // Search table
      substituted: 'Substituted',
      days: 'Days',
      available: 'Available',
      volume_kg: 'Volume (kg)',
      comment: 'Comment',
      n_a: 'N/A',
      pack: 'Pack',

      // Shipment date dialog
      enabled: 'Enabled',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'place',
      length_m: 'Length (m)',
      height_m: 'Height (m)',
      width_m: 'Width (m)',
      value_with_currency: 'Value ({{currency}})',

      // Shipment total table
      places: 'Places',
      volume_m3: 'Volume (㎥)',
      schedule: 'Schedule',
      choose_date: 'Choose date',

      // Sign up dialog
      company_name: 'company name',
      contact_phone: 'contact phone',
      confirm_password: 'confirm password',
      error_password_not_match: 'Passwords do not match',

      //Manuals page
      tab_register: 'register',
      tab_quotations: 'make quotations',
      tab_offers: 'download offers',
      tab_api: 'use API',

      //TopUp Dialog
      error_amount: 'Incorrect amount',
      error_something: 'Something went wrong, contact us',
    },
  },
  ru: {
    translation: {
      //NAVIGATION
      home: 'Главная',
      about: 'О нас',
      product_list: 'Список продукции',
      gallery: 'Галерея',
      contact: 'Контакты',
      hello: 'Привет',

      search: 'Поиск',
      cart: 'Корзина',
      orders: 'Заказы',
      dispatch: 'Отправка',
      balance: 'Баланс',
      offers: 'Предложения',

      sign_up: 'Регистрация',
      log_in: 'Вход',
      log_out: 'Выйти',
      profile: 'Профиль',
      //HOME PAGE
      shop_now: 'Смотреть каталог',
      news: 'Новости и статьи',
      oem_brands: 'Оригинальные бренды',
      aftermarket_brands: 'Бренды вторичного рынка',
      view_all: 'Все новости',

      //footer
      quick_links: 'Быстрые ссылки',
      follow_us: 'Cледите за нами',
      my_account: 'Профиль',
      search_product: 'Поиск продукции',
      shopping_cart: 'Корзина',
      resources: 'Ресурсы',
      location: 'Расположение офиса',
      faq: 'FAQ',

      //about page
      companyProfileHeading: 'Профиль Компании',
      whyChooseUsHeading: 'Почему выбирают нас',
      qualityPolicyHeading: 'Политика качества',
      item1: 'Ремни ГРМ, Комплект ГРМ, Цепи ГРМ',
      item2: 'Ребристые ремени, Вентиляторные ремень',
      item3: 'Поршени, Гильзы',
      item4: 'Кольца поршневые',
      item5: 'Сальники клапана, Сальники маслянные',
      item6: 'Детали подвески',
      item7: 'Анкерный манжет ШРУСа',
      item8: 'Прокладки крышек клапанов, Поддоны',
      item9: 'Защитный манжет амортизаторов',
      item10: 'Тормозные колодки, Тормозные башмаки',
      item11: 'Воздушные фильтры, Масляные фильтры, Топливные фильтры',
      item12: 'Подшипники коленвала, Шатунные подшипники',
      item13: 'Клапан двигателя, Направляющая клапана',
      item14: 'Подшипники двигателя, Подшипники колеса',

      //contact page
      get_in_touch: 'Связаться с нами',
      contact_us: 'Контакты',
      email_address: 'Адрес Email',
      office_location: 'Расположение офиса',
      enquire_now: 'Задать вопрос',
      firstName: 'Имя*',
      lastName: 'Фамилия*',
      phoneNumber: 'Телефон*',
      message: 'Cообщение*',
      message_placeholder: 'Напишите своё сообщение',
      submit_form: 'Отправить',
      //user dialog
      user_name: 'НАИМЕНОВАНИЕ',
      user_country: 'СТРАНА',
      user_zip: 'ИНДЕКС',
      user_state: 'ШТАТ/ПРОВИНЦИЯ',
      user_city: 'ГОРОД',
      user_street: 'УЛИЦА',
      user_building: 'ДОМ',
      user_flat: 'КВАРТИРА/ОФИС',
      user_phone: 'ТЕЛЕФОН',
      user_email: 'ЭЛЕКТРОННАЯ ПОЧТА',
      user_tax: 'НАЛОГОВЫЙ НОМЕР',
      user_currency: 'ВАЛЮТА',
      user_contact_person: 'КОНТАКТНОЕ ЛИЦО',
      user_contact_email: 'EMAIL',
      user_contact_phone: 'КОНТАКТНЫй ТЕЛЕФОН',
      save: 'СОХРАНИТЬ',

      //verify email
      verify_email_processing: 'Обработка...',
      verify_email_heading: 'Подтверждение адреса email',
      verify_email_text: 'Мы отправили вам письмо на',
      verify_email_text2:
        'Пожалуйста, проверьте свой почтовый ящик и перейдите по ссылке в сообщении',
      close_text: 'Закрыть',
      // Search page
      by_part_number: 'Поиск по номеру',
      by_excel_file: 'Проценка файлом',
      to_cart: 'В корзину',
      export: 'Экспорт',
      upload: 'Загрузить',
      max_days: 'Не позднее',
      template: 'Шаблон',
      demo_prices_log_in: 'Вы видите ДЕМО цены. Авторизуйтесь',
      demo_prices_complete_registration:
        'Вы видите ДЕМО цены. Внесите депозит USD500, чтобы завершить регистрацию',
      for_order: 'ЗАКАЗ',
      error_quotation: 'Ошибка выполнения котировки',
      quotation_differs:
        'По части позиций отличается цена, наличие или срок поставки',
      where_to_dispatch: 'Доставка',

      // Balance page
      cannot_export_invoice: 'Ошибка выгрузки документа',
      start_date: 'С',
      end_date: 'ПО',
      show: 'Показать',
      topup_online: 'Пополнить картой',
      bank_transfer: 'Банковский перевод',
      document: 'Документ',
      debit: 'Дебет',
      credit: 'Кредит',

      // Basket page
      cannot_select_item: 'Ошибка при выборе позиций в заказ',
      cannot_change_quantity: 'Ошибка изменения количества',
      cannot_apply_changes: 'Ошибка подтверждения изменений',
      cannot_order_selected: 'Ошибка размещения заказа',
      cannot_delete_selected: 'Ошибка при удалении',
      cannot_change_ref: 'Ошибка изменения Reference',
      order: 'Заказ',
      remove: 'Убрать',
      order_accepted:
        'Ваш заказ № {{orderId}} в сумме {{currency}}{{amount}} принят.',
      order_not_accepted:
        'Ваш заказ не принят, код ошибки (code = {{orderId}})',
      activate_account: 'Свяжитесь с нами для активации аккаунта',
      insufficient_funds: 'Недостаточно средств на балансе',
      no_items: 'Не выбраны позиции в заказ',
      incorrect_items: 'Есть некорректные позиции в корзине',
      duplication: 'Дублирование',
      basket_log_in: 'Для размещения заказов авторизуйтесь',
      basket_complete_registration:
        'Для размещения заказов внесите депозит USD500, чтобы завершить регистрацию',

      // Orders page
      part_number: 'Номер детали',
      order_number: 'Номер заказа',
      reference: 'Референс',
      all: 'Все',

      // Password reset page
      submit: 'Сменить',
      new_password: 'НОВЫЙ ПАРОЛЬ',

      // Shipments page
      cannot_update_shipment_date: 'Ошибка изменения даты отгрузки',

      // Balance topup dialog
      amount_with_currency: 'СУММА ({{currency}})',
      charge: 'КОМИССИЯ: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'ПОПОЛНИТЬ',

      // Balance total table
      balance_with_currency: 'Баланс ({{currency}})',
      in_orders: 'В работе',
      in_cart: 'В корзине',
      pay_for_cart: 'Оплатить до корзины',
      ready_to_ship: 'К отгрузке',
      pay_to_ship: 'Оплатить к отгрузке',

      // Basket table
      brand: 'Марка',
      price_with_currency: 'Цена ({{currency}})',
      quantity: 'Количество',
      total_with_currency: 'Сумма ({{currency}})',
      weight_kg: 'Вес (кг)',
      booking: 'Склад',
      delivery: 'Доставка',
      description: 'Описание',

      // Basket total table
      to_order: 'К заказу',
      available_for_order: 'Доступно к заказу',
      pay_for_order: 'Оплатить к заказу',
      items: 'Позиций',

      // Captcha
      captcha_field_placeholder: 'ВВЕДИТЕ СИМВОЛЫ НА КАРТИНКЕ',

      //Catalog page
      offers_log_in: 'Для скачивания предложений авторизуйтесь',
      offers_complete_registration:
        'Для скачивания предложений внесите депозит USD500, чтобы завершить регистрацию',

      // Catalog table
      offer: 'Предложение',
      download: 'Скачать',
      lines: 'Строк',
      updated: 'Обновлено',

      //Email verified page
      email_verified: 'Почтовый адрес подтвержден',

      // Log In dialog
      email: 'email',
      password: 'пароль',
      forgot_password: 'Забыли пароль?',
      reset_link_sent: 'Ссылка для сброса пароля отправлена вам на почту',

      // Orders table
      date: 'Дата',
      order_price: 'Цена заказа',
      sale_price: 'Цена продажи',
      ordered: 'Заказано',
      purchased: 'Закуплено',
      shipped: 'Отгружено',
      refused: 'Отказано',
      state_date: 'Дата состояния',

      // Search input
      search_input_placeholder: 'Поиск...',

      // Search table
      substituted: 'Замена',
      days: 'Срок',
      available: 'Наличие',
      volume_kg: 'Объем (кг)',
      comment: 'Комментарий',
      n_a: 'НЕТ',
      pack: 'Упаковка',

      // Shipment date dialog
      enabled: 'Активно',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'Грузоместо',
      length_m: 'Длина (м)',
      height_m: 'Высота (м)',
      width_m: 'Ширина (м)',
      value_with_currency: 'Сумма ({{currency}})',

      // Shipment total table
      places: 'Грузомест',
      volume_m3: 'Объем (㎥)',
      schedule: 'Дата отгрузки',
      choose_date: 'Выберите дату',

      // Sign up dialog
      company_name: 'Наименование компании',
      contact_phone: 'Контактный телефон',
      confirm_password: 'Подтвердите пароль',
      error_password_not_match: 'Пароли не совпадают',

      //Manuals page
      tab_register: 'Зарегистрироваться',
      tab_quotations: 'Проценить',
      tab_offers: 'Скачать прайсы',
      tab_api: 'Подключиться к API',

      //TopUp Dialog
      error_amount: 'Неверная сумма',
      error_something: 'Что-то пошло не так, свяжитесь с нами',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
