import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import SocialMediaLinks from './SocialMediaLinks';
import FooterContactDetails from './FooterContactDetails';

const PageFooter = ({ classes }) => {
  const { t } = useTranslation();
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));

  return (
    <div className={classes.footer}>
      <Grid container fluid spacing={16} className={classes.root}>
        <Grid item md={5} xs={12}>
          <Typography
            variant="h6"
            className={classNames(classes.title, classes.text)}
          >
            {t('about')}
          </Typography>
          <Typography
            variant="body1"
            className={classes.text}
            style={{ paddingBottom: '20px' }}
          >
            Allied Motors Trading FZCO - Online Parts Division is a leading
            provider of genuine automotive spare parts, committed to delivering
            exceptional service and competitive pricing. Our extensive inventory
            caters to customers globally, supported by our advanced online
            platform and sophisticated stock management system. Automated order
            processing ensures efficiency and a seamless experience for all our
            clients.
          </Typography>
        </Grid>
        <Grid item md={2} sm={6}>
          <Typography
            noWrap
            variant="h6"
            className={classNames(classes.title, classes.text)}
          >
            {t('quick_links')}
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <List className={classes.list}>
                {isLoggedIn && (
                  <ListItem disableGutters>
                    <Link to="/balance" className={classes.link}>
                      <ListItemText
                        className={classes.text}
                        primary={'Account'}
                      />
                    </Link>
                  </ListItem>
                )}
                <ListItem disableGutters>
                  <Link
                    to="/search"
                    className={classNames(classes.link, classes.text)}
                  >
                    <ListItemText className={classes.text} primary={'Search'} />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/basket" className={classes.link}>
                    <ListItemText className={classes.text} primary={'Cart'} />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/orders" className={classes.link}>
                    <ListItemText className={classes.text} primary={'Orders'} />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/about" className={classes.link}>
                    <ListItemText
                      className={classes.text}
                      primary={t('about')}
                    />
                  </Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <List className={classes.list}>
                <ListItem disableGutters>
                  <Link to="/manuals" className={classes.link}>
                    <ListItemText className={classes.text} primary={'Help'} />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <a
                    href="/termsandconditions.pdf"
                    className={classNames(classes.link, classes.text)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemText className={classes.text} primary={'Terms'} />
                  </a>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/news" className={classes.link}>
                    <ListItemText className={classes.text} primary={'News'} />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/gallery" className={classes.link}>
                    <ListItemText
                      style={{ textWrap: 'nowrap' }}
                      className={classes.text}
                      primary={'Our facilities'}
                    />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/contact" className={classes.link}>
                    <ListItemText
                      style={{ textWrap: 'nowrap' }}
                      className={classes.text}
                      primary={t('contact_us')}
                    />
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} sm={6}>
          <Typography
            variant="h6"
            className={classNames(classes.title, classes.text)}
          >
            {t('follow_us')}
          </Typography>

          <SocialMediaLinks />
        </Grid>
        <Grid item md={3} sm={6}>
          <Typography
            variant="h6"
            className={classNames(classes.title, classes.text)}
          >
            {t('location')}
          </Typography>
          <FooterContactDetails />
          <List></List>
        </Grid>

        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            classes={{ root: classes.dividerRoot }}
          />
          <Typography
            variant="h6"
            align="center"
            style={{ fontSize: '12.8px' }}
            className={classNames(classes.title, classes.text)}
          >
            Copyright© {getCurrentYear()} Allied Motors Pats. All rights
            reserved.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (theme) => {
  return {
    footer: {
      backgroundColor: '#0032A0',
      minHeight: '36px',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: '24px',
      },
    },
    toolbar: {
      flex: '1 0 0px',
      justifyContent: 'space-between',
    },
    name: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing.unit,
    },
    paylogo: {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        height: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        '& > img': {
          height: '20px',
        },
      },
    },
    address: {
      color: theme.palette.custom.logo,
      fontWeight: 'normal',
    },
    list: {
      '& li': {
        paddingTop: 0,
      },
    },
    link: {
      color: '#fff',
      fontWeight: 'normal',
    },
    root: {
      padding: '30px 10px',
      color: '#fff',
      maxWidth: '1264px',
      margin: '0 auto',
    },
    title: {},
    text: {
      color: '#ffffff !important',
      '&&& span': {
        color: '#ffffff',
      },
    },
    dividerRoot: {
      backgroundColor: '#fff',
      height: '5px',
      margin: '10px 0',
    },
  };
};

export default withStyles(styles)(PageFooter);
