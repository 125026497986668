import axios from 'axios';

const STRAPI_URL = process.env.REACT_APP_STRAPI_API_URL;

const fetchImgs = async (lang) => {
  try {
    const response = await axios.get(
      `${STRAPI_URL}/gallery-imgs?populate=img`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error while fetching gallery images', error);
  }
};

export default fetchImgs;
