import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Page } from '../components';
import {
  Typography,
  Grid,
  Paper,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const AboutPage = ({ classes }) => {
  const { t } = useTranslation();

  const listItems = [
    <Typography paragraph className={classes.text}>
      <b>Extensive Experience: </b> Decades of expertise in the auto spare parts
      market.
    </Typography>,
    <Typography paragraph className={classes.text}>
      <b>Competitive Pricing: </b> Superior rates compared to competitors.
    </Typography>,
    <Typography paragraph className={classes.text}>
      <b>Comprehensive Inventory: </b> A vast range of genuine, OEM, and
      aftermarket parts.
    </Typography>,
    <Typography paragraph className={classes.text}>
      <b>Efficient Service: </b> Advanced stock management and automated order
      processing online platform.
    </Typography>,
    <Typography paragraph className={classes.text}>
      <b>Modern Technology:</b> A state-of-the-art online portal built with the
      latest web technologies.
    </Typography>,
    <Typography paragraph className={classes.text}>
      <b>Customer Testimonials:</b> "Allied Motors Parts provided us with
      exactly what we needed, right when we needed it." - Satisfied Customer
    </Typography>,
    <Typography paragraph className={classes.text}>
      <b>Sustainability: </b> We are committed to environmentally friendly
      practices and sustainable sourcing.
    </Typography>,
    <Typography paragraph className={classes.text}>
      <b>Community Involvement: </b> We actively support local communities
      through various initiatives.
    </Typography>,
  ];

  const [content, setContent] = useState('');
  const column1 = listItems.slice(0, listItems.length / 2);
  const column2 = listItems.slice(listItems.length / 2);
  useEffect(() => {
    const fetchContent = async () => {
      const result = await axios.get('/about.html');
      setContent(result.data);
    };
    fetchContent();
  }, []);

  return (
    <Page>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.mainHeading}>
            {t('about')}
          </Typography>
        </Grid>
        <Typography variant="h5">
          <b> Welcome to Allied Motors Trading FZCO – Online Parts Division </b>
        </Typography>
        <Typography variant="h5">
          <b> Discover Quality, Reliability, and Service Excellence </b>
        </Typography>
        <Typography paragraph className={classes.text}>
          Our mission is to redefine the way you shop for automotive parts
          online. As a premier destination for Original Equipment Manufacturer
          (OEM) and aftermarket automotive parts, we combine decades of industry
          experience with cutting-edge technology to provide a seamless and
          satisfying shopping experience.
        </Typography>
        <Typography variant="h5">
          <b>Who we are</b>
        </Typography>
        <Typography paragraph className={classes.text}>
          Based in the Business Centric city of Dubai, Allied Motors Trading
          FZCO has carved out a niche in the auto spare parts market by offering
          a wide array of genuine parts, OEM parts, and aftermarket parts at
          highly competitive rates. Our reputation for excellence extends from
          the UAE to customers worldwide.
        </Typography>
        <Typography variant="h5">
          <b>Our Commitment</b>
        </Typography>
        <Typography paragraph className={classes.text}>
          We are dedicated to delivering exceptional service to our customers in
          the UAE and across the globe. Our commitment to excellence ensures
          that you receive not only top-quality products but also unparalleled
          customer support. Our competitive pricing structure is designed to
          offer you the best value in the market.
        </Typography>
        <Typography variant="h5">
          <b>Our Inventory</b>
        </Typography>
        <Typography paragraph className={classes.text}>
          We take pride in maintaining a large inventory of automotive parts in
          our online store, which guarantees easy availability and efficient
          service. Our sophisticated stock management system, featuring an
          electronic parts inventory and automated order placement, is designed
          to meet your needs promptly and accurately.
        </Typography>
        <Typography variant="h5">
          <b>Cutting-Edge Technology</b>
        </Typography>
        <Typography paragraph className={classes.text}>
          Our online portal is built with modern web development tools and
          advanced technologies, ensuring a seamless and efficient shopping
          experience. This state-of-the-art platform provides a user-friendly
          interface and reliable performance, so you can easily find and order
          the automotive parts you need.
        </Typography>
        <Typography variant="h6" align="center">
          <b>Why Choose Us?</b>
        </Typography>
        <List className={classes.list}>
          {listItems.map((position, index) => (
            <ListItem key={index} className={classes.item}>
              <ListItemIcon>
                <FiberManualRecordIcon className={classes.itemIcon} />{' '}
              </ListItemIcon>
              <Typography variant="body1">{position}</Typography>
            </ListItem>
          ))}
        </List>
        <Typography variant="h5">
          <b>Future Goals</b>
        </Typography>
        <Typography paragraph className={classes.text}>
          Looking ahead, Allied Motors Trading FZCO – Online Parts Division aims
          to expand our product range and enhance our service offerings to meet
          the evolving needs of our customers. Stay tuned for exciting updates
          and innovations from our team.
        </Typography>
        <Typography paragraph className={classes.text}>
          We invite you to explore our online store and experience the
          difference with Allied Motors Parts. For any inquiries or assistance,
          feel free to contact us—we are here to help!
        </Typography>
      </Grid>
    </Page>
  );
};

const styles = (theme) => {
  return {
    mainHeading: {
      fontWeight: '600',
      display: 'block',
      margin: '10px 0',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: '1rem',
    },
    subHeading: {
      display: 'block',
      color: '#194681',
      margin: '10px 0',
    },
    item: {
      paddingTop: '0',
    },
    itemIcon: {
      fontSize: '12px',
      marginTop: '8px',
      color: '#000000BB',
    },
    image: {
      width: 'clamp(300px,100%,450px)',
      padding: '10px',
      margin: '10px auto',
    },
    text: {
      paddingTop: '10px',
      fontSize: '1.1rem',
    },
  };
};

export default withStyles(styles)(AboutPage);
