import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  contactDetails: {
    padding: '20px',
    borderRadius: '5px',
    color: '#fff',
    maxWidth: '400px',
    margin: 'auto',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    marginRight: '15px',
  },
  contactText: {
    '& h3': {
      margin: 0,
    },
    '& p': {
      margin: 0,
      color: '#fff',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
});

const FooterContactDetails = ({ classes }) => {
  return (
    <div className={classes.contactDetails}>
      <div className={classes.contactItem}>
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          size="xs"
          className={classes.icon}
        />
        <div className={classes.contactText}>
          <Typography variant="body2" noWrap>
            Allied Motors Trading FZCO (Branch)
          </Typography>
          <Typography variant="body2" noWrap>
            Warehouse: RA08HA01 & HA02
          </Typography>
          <Typography variant="body2" noWrap>
            PO Box 7211, Jebel Ali Free Zone - Dubai, UAE.
          </Typography>
        </div>
      </div>
      <div className={classes.contactItem}>
        <FontAwesomeIcon icon={faEnvelope} size="xs" className={classes.icon} />
        <div className={classes.contactText}>
          <Typography variant="body2">
            <a href="mailto:info@alliedmotorsparts.com">
              info@alliedmotorsparts.com
            </a>
          </Typography>
        </div>
      </div>
      <div className={classes.contactItem}>
        <FontAwesomeIcon icon={faPhone} size="xs" className={classes.icon} />
        <div className={classes.contactText}>
          <Typography variant="body2">
            <a href="tel:+97148046700">+971 48046700</a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(FooterContactDetails);
