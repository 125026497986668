import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from './Select';
import { topupVariants } from '../api/balance-api';
import axios from 'axios';

import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  withStyles,
} from '@material-ui/core';
import { getCookie } from '../utils/cookies';

const BalanceTopupDialog = ({ classes, open, onClose, topupId }) => {
  const [error, setError] = useState('');
  const [variants, setVariants] = useState([]);
  const [variant, setVariant] = useState(undefined);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState(getCookie('currency'));
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    topupVariants().then((data) => {
      setVariants(
        data.map((row) => {
          return {
            value: row['variant'],
            title: row['description'],
            percent: row['service_percent'],
            fixed: row['service_fixed'],
          };
        })
      );

      if (data && Array.isArray(data) && data.length) {
        setVariant({
          value: data[0].variant,
          title: data[0].description,
          percent: data[0].service_percent,
          fixed: data[0].service_fixed,
        });

        setCurrency(data[0].currency);
      }
    });
  }, [topupId]);

  function handleAmountChange(event) {
    const { target } = event;
    setAmount(target.value);
  }

  function handleVariantChange(value) {
    setVariant(variants.find((i) => i.value === value));
  }

  const handleTopup = async () => {
    if (!amount || amount <= 0) {
      setError(t('error_amount'));
      return;
    }

    try {
      setDisabled(true);

      const response = await axios.get(
        `/payinit_${variant.value}.php?user=${topupId}&amount=${amount}&currency=${currency}`
      );

      if (!response || !response.data || !response.data.url) {
        throw t('error_something');
      }

      setDisabled(false);

      onClose();

      window.location.href = response.data.url;
    } catch (e) {
      setError(e.message);
    }

    setDisabled(false);

    return;
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      topupId={topupId}
    >
      <DialogContent className={classes.dialog}>
        <form>
          <TextField
            name="amount"
            type="amount"
            placeholder={t('amount_with_currency', { currency })}
            //className={classes.textField}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={handleAmountChange}
            disabled={disabled}
          />
          {variant ? (
            <div className={classes.errorChip}>
              <small>
                {t('charge', {
                  percent: variant.percent,
                  fixed: variant.fixed,
                  currency,
                })}
              </small>
            </div>
          ) : null}
          {variants && variants.length > 1 ? (
            <Select
              name="variant"
              items={variants}
              value={variant.value}
              fullWidth
              onChange={handleVariantChange}
            />
          ) : null}

          <Button
            className={classes.actionButton}
            color="primary"
            onClick={handleTopup}
            fullWidth
            disabled={disabled}
          >
            {t('proceed')}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

BalanceTopupDialog.defaultProps = {
  open: true,
};

const styles = (theme) => {
  return {
    dialog: {
      backgroundColor: '#FFF',
    },

    actionButton: {
      width: '95%',
      height: '32px',
      marginLeft: theme.spacing.unit,
    },

    errorChip: {
      width: '100%',
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '14px',
      marginBottom: theme.spacing.unit * 3,
    },
  };
};

export default withStyles(styles)(BalanceTopupDialog);
