import React from 'react';
import theme from './theme';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import AppRouter from './AppRouter';
import { SearchContextProvider } from './store/searchContext';
import { FileProvider } from './store/fileContext';

const App = () => (
  <SearchContextProvider>
    <FileProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <AppRouter />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </FileProvider>
  </SearchContextProvider>
);

export default App;
